import { Component, Input, OnInit } from '@angular/core';
import { PartnerCardModel } from './o-partner-cards.model';

@Component({
    selector: 'o-partner-cards',
    templateUrl: './o-partner-cards.component.html',
    styleUrls: ['./o-partner-cards.component.scss'],
})
export class PartnerCardsComponent implements OnInit {
    @Input() partners!: PartnerCardModel[];
    constructor() {}

    ngOnInit(): void {}
}
