import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TitleModule } from '../../../../@shared/atomic/atoms/a-title/a-title.module';
import { ParagraphModule } from '../../../../@shared/atomic/atoms/a-paragraph/a-paragraph.module';
import { FormFieldModule } from '../../../../@shared/atomic/molecules/m-form-field/m-form-field.module';
import { ButtonModule } from '../../../../@shared/atomic/atoms/a-button/a-button.module';
import { PartnerCardsComponent } from './o-partner-cards.component';
import { ImageModule } from '../../../../@shared/atomic/atoms/a-image/a-image.module';
import { LinkModule } from '../../../../@shared/atomic/atoms/a-link/a-link.module';

@NgModule({
    declarations: [PartnerCardsComponent],
    imports: [CommonModule, ImageModule, LinkModule],
    exports: [PartnerCardsComponent],
})
export class PartnerCardsModule {}
