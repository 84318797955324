import { Component, OnInit, Input } from '@angular/core';
import { environment } from '@env/environment';

@Component({
    selector: 'a-image',
    templateUrl: './a-image.component.html',
    styleUrls: ['./a-image.component.scss'],
})
export class ImageComponent implements OnInit {
    @Input() bgImageConfig: any;
    @Input() fullHeight!: boolean;
    @Input() cardHeight!: boolean;
    @Input() smallHeight!: boolean;
    @Input() mediumImage!: boolean;
    @Input() bgRightPositioMobile!: boolean;
    @Input() greyScale: any;
    @Input() imageConfig: any;
    @Input() alt: string = '...';
    @Input() fixedSize: boolean = true;
    @Input() overlay: boolean = false;
    @Input() containerClass?: string;
    webpUrl: string = '';

    constructor() {}

    ngOnInit(): void {
        if (this.imageConfig && this.imageConfig.includes(environment.awsS3.baseUrl))
            this.webpUrl = this.imageConfig.replace(/\.(png|jpg|jpeg)/i, '.webp');
        if (this.bgImageConfig)
            this.webpUrl = this.bgImageConfig.includes(environment.awsS3.baseUrl)
                ? this.bgImageConfig.replace(/\.(png|jpg|jpeg)/i, '.webp')
                : this.bgImageConfig;
    }
}
