import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoaderComponent } from './a-loader.component';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { AngularSvgIconModule } from 'angular-svg-icon';

export function playerFactory() {
    return player;
}

@NgModule({
    declarations: [LoaderComponent],
    imports: [LottieModule.forRoot({ player: playerFactory }), AngularSvgIconModule, CommonModule],
    exports: [LoaderComponent],
})
export class LoaderModule {}
