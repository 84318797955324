<nav
    #nav
    *ngIf="showComponent"
    (scroll)="onWindowScroll()"
    class="navbar navbar-expand-lg fixed-top o-navbar"
    [ngClass]="[componentClass ? componentClass : '']">
    <div class="menu-container">
        <ng-container *ngTemplateOutlet="templateLogo"></ng-container>
        <div class="hide-desktop hide-mobile">
            <a-icon
                source="/assets/icons/icon-bars.svg"
                (click)="openMenu()"></a-icon>
        </div>
    </div>
    <div
        class="collapse navbar-collapse justify-content-end"
        id="navbarSupportedContent">
        <ng-container *ngTemplateOutlet="templateNavItems"></ng-container>
        <ng-content></ng-content>
    </div>

    <button
        *ngIf="button"
        type="button"
        class="hide-mobile m-0 btn btn-outline-primary btn-sm"
        (click)="click()">
        {{ button.label | translate }}
    </button>
    <div
        *ngIf="showThemeToggle"
        class="hide-mobile">
        <a-theme-toggle></a-theme-toggle>
    </div>
</nav>

<ng-template
    #modalmenu
    let-modal>
    <div class="o-navbar mobile-menu hide-desktop">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <ng-container *ngTemplateOutlet="templateLogo"></ng-container>
                    <svg-icon
                        data-dismiss="modal"
                        aria-label="Close"
                        class="icon-close"
                        [src]="iconClose"
                        (click)="modal.dismiss('close')"></svg-icon>
                </div>
                <div
                    data-dismiss="modal"
                    class="modal-body">
                    <ng-container *ngTemplateOutlet="templateNavItems"></ng-container>
                    <button
                        *ngIf="buttonMobile"
                        type="button"
                        class="hide-desktop m-0 btn btn-dark btn-sm"
                        (click)="click(true)">
                        {{ buttonMobile.label | translate }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #templateLogo>
    <a
        aria-label="logo"
        class="navbar-logo"
        data-dismiss="modal"
        routerLink="/">
        <svg-icon [src]="logoUrl"></svg-icon>
    </a>
</ng-template>
<ng-template #templateNavItems>
    <m-navigation
        [navlinks]="items"
        (closeModal)="closeMenu()"></m-navigation>
</ng-template>
